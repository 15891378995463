* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  background-color: $gray-bg;

  #confetti {
    position: absolute;
    z-index: -9999;
  }
}

// Home Sections
.home {
  &__header {
    position: relative;
    display: flex;
    overflow: hidden;
    min-height: 100vh;
    align-items: center;
    justify-content: center;
  }

  &__game {
    position: relative;
    display: flex;
    overflow: hidden;
    min-height: 100vh;
    align-items: center;
    justify-content: center;
  }

  &__footer {
    position: relative;
    display: flex;
    overflow: hidden;
    min-height: 100vh;
    align-items: center;
    justify-content: center;
  }
}

// Home Header
.header {
  &__graphic {
    &.hand {
      display: none;

      @media (min-width: $screen-md) {
        position: absolute;
        z-index: -1;
        display: block;
        top: 180px;
        left: 0;
        height: auto;
        width: 16vw;
        min-width: 260px;
      }

      @media (min-width: $screen-lg) {
        top: 140px;
        left: 0;
        width: 26vw;
      }

      @media (min-width: $screen-xlg) {
        top: 80px;
        left: 0;
        width: 33vw;
        max-width: 470px;
      }

      img {
        display: none;

        @media (min-width: $screen-sm) {
          position: relative;
          display: block;
          height: auto;
          max-width: 100%;
        }
      }
    }

    &.telephone {
      display: none;

      @media (min-width: $screen-md) {
        position: absolute;
        z-index: -1;
        display: block;
        top: 45px;
        right: 30px;
        height: auto;
        width: 34vw;
        min-width: 500px;
        transform: rotate(42deg);
      }

      @media (min-width: $screen-lg) {
        right: 70px;
        height: auto;
        width: 37vw;
      }

      @media (min-width: $screen-xlg) {
        top: 55px;
        right: 90px;
        height: auto;
        width: 44vw;
        min-width: 650px;
      }

      img {
        display: none;

        @media (min-width: $screen-sm) {
          position: relative;
          display: block;
          height: auto;
          max-width: 100%;
        }
      }
    }
  }

  &__content {
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: column;
  }

  &__logo {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 28px;

    @media (min-width: $screen-sm) {
      margin-bottom: 56px;
    }

    .logo {
      margin-bottom: 16px;

      img {
        position: relative;
        display: block;
        height: 35px;
        width: 89px;

        @media (min-width: $screen-sm) {
          height: 45px;
          width: 99px;
        }
      }
    }

    span {
      position: relative;
      z-index: 1;
      color: $gray-dark;
      font-size: 1.25rem;
      text-transform: uppercase;

      @media (min-width: $screen-sm) {
        font-size: 1.5rem;
      }

      &:before {
        position: absolute;
        content: '';
        bottom: 0;
        left: -8px;
        height: 10px;
        z-index: -1;
        width: calc(100% + 16px);
        background-color: $white;
      }
    }
  }

  &__intro {
    margin-bottom: 24px;

    @media (min-width: $screen-md) {
      margin-bottom: 48px;
    }

    h1 {
      color: $gray-dark;
      font-size: 3rem;
      line-height: 1.375;
      margin-bottom: 16px;
      text-align: center;
      font-weight: $black-type;
      text-transform: uppercase;
    }

    p {
      width: 80%;
      margin: 0 auto;
      font-size: 1rem;
      line-height: 1.5;
      text-align: center;
      color: $gray-darker;

      @media (min-width: $screen-md) {
        width: 60%;
      }

      @media (min-width: $screen-lg) {
        width: 40%;
      }
    }
  }

  &__heading {
    &--desktop {
      display: none; 

      @media (min-width: $screen-md) {
        display: block;
      }
    }

    &--mobile {
      display: flex;
      justify-content: center;

      img {
        position: relative;
        display: block;
        height: 139px;
        max-width: 305px;
        margin-bottom: 32px;
           
        @media (min-width: $screen-sm) {
          height: 193px;
          max-width: 424px;
        }
      }
      
      @media (min-width: $screen-md) {
        display: none;
      }
    }
  }

  &__cta {
    .btn {
      position: relative;
      transition: all .3s ease-in-out;
    }

    .btn-shadow {
      box-shadow: 0.3em 0.3em 0 0 $white, inset 0 0 0 0 $white;
    
      &:hover, &:focus, &:active {
        box-shadow: inset 6em 3.5em 0 0 $green;
      }
    }
  }
}

// Home Game
.game {
  &__background {
    text-indent: -99999px;
  }

  &__canvas-wrapper {
    position: relative;
  }

  &__canvas {
    position: relative;
    display: block;
    height: auto;
    width: 45vw;
    z-index: 99;
    min-width: 250px;
    max-width: 500px;
    margin-bottom: 8px;
    border: 3px solid $white;

    @media (min-width: $screen-sm) {
      margin-bottom: 16px;
    }
  }

  &__overlay {
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 999;
    padding: 24px;
    align-items: center;
    flex-direction: column;
    border: 3px solid $green;
    justify-content: center;
    background-color: $green;
  }

  &__overlay-content {
    color: $white;
    text-align: center;

    h2 {
      font-size: 1.5rem;
      font-weight: $bold;
      margin-bottom: 8px;
    }

    p {
      font-size: 1rem;
    }
  }

  &__over {
    display: none; 

    &.show {
      position: absolute;
      display: flex;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 999;
      padding: 24px;
      align-items: center;
      flex-direction: column;
      border: 3px solid $green;
      justify-content: center;
      background-color: $green;
    }
  }

  &__over-inner {
    margin-bottom: 32px;
  }

  &__over-heading {
    margin-bottom: 16px;

    h2 {
      color: $white;
      font-size: 1.5rem;
      font-weight: $bold;
      text-transform: uppercase;
    }
  }

  &__over-score {
    p {
      color: $white;
      font-weight: bold;
      text-align: center;
      font-size: 1.25rem;
    }
  }

  &__over-cta {
    text-align: center;

    .btn {
      position: relative;
      background-color: $gray-dark;
      transition: all .3s ease-in-out;
    }

    .btn-shadow {
      box-shadow: 0.3em 0.3em 0 0 $white, inset 0 0 0 0 $white;
    
      &:hover, &:focus, &:active {
        box-shadow: inset 6em 3.5em 0 0 $gray-dark;
      }
    }
  }

  &__scoreboard {
    display: none;

    &.show {
      position: absolute;
      display: flex;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 999;
      padding: 24px;
      overflow: scroll;
      flex-direction: column;
      border: 3px solid $green;
      justify-content: space-between;
      background-color: $green;
    }
  }

  &__status {
    display: flex;
    flex-direction: row;
    margin-bottom: 16px;
    justify-content: space-between;
  }

  &__portraits {
    img {
      display: none;
    }
  }

  &__snake-head {
    img {
      display: none;
    }
  }

  &__keyboard {
    position: relative;
    height: 110px;

    @media (min-width: $screen-sm) {
      height: 130px;
    }
  }
}


.status {
  position: relative;

  &__wrapper {
    display: flex;
    flex-direction: column;

    p {
      font-size: 1rem;
      color: $gray-dark;
      font-weight: $bold;
  
      @media (min-width: $screen-sm) {
        font-size: 1.25rem;
      }
    }
  }

  &__cta {
    .btn {
      position: relative;
      transition: all .3s ease-in-out;
    }

    .btn-shadow {
      box-shadow: 0.3em 0.3em 0 0 $white, inset 0 0 0 0 $white;
    
      &:hover, &:focus, &:active {
        box-shadow: inset 6em 3.5em 0 0 $green;
      }
    }
  }
}

.scoreboard {
  &__heading {
    margin-bottom: 16px; 

    h2 {
      color: $white;
      text-align: center;
      font-weight: $black-type;
      text-transform: uppercase;
      font-size: clamp(16px, 1.8vw, 24px);
    }

    button {
      position: absolute;
      top: 24px;
      right: 24px;

      span {
        color: $white;
        font-weight: $bold;
        font-size: clamp(16px, 1.8vw, 24px);
      }
    }
  }

  &__column-heading {
    display: flex;
    flex-direction: row;
    margin-bottom: 16px;
    justify-content: space-between;

    h3 {
      color: $white;
      text-transform: uppercase;
      font-size: clamp(14px, 1.3vw, 18px);
    }
  }

  &__left {
    display: flex;
    flex-direction: row;

    .rank {
      padding: 0 16px;
    }
  }

  &__entries {
  }

  &__entry {
    display: flex;
    margin-bottom: 16px;
    flex-direction: row;
    justify-content: space-between;    

    &:last-of-type {
      margin-bottom: 0;
    }

    p {
      color: $white;
      font-size: 1.2vw;
      font-weight: $bold;
      font-size: clamp(14px, 1.3vw, 18px);
    }
  }

  &__cta {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-top: 32px;
  }

  &__play {
    margin-bottom: 16px;
  }
}

.keyboard {
  position: relative;
  display: flex;
  height: inherit;
  align-items: center;

  button {
    cursor: pointer;

    img {
      position: relative;
      display: block;
      height: 35px;
      width: 35px;

      @media (min-width: $screen-sm) {
        height: 30px;
        width: 30px;
      }
    }
  }

  #up {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  #down {
    position: absolute;
    top: 75px;
    left: 50%;
    transform: translateX(-50%);

    @media (min-width: $screen-sm) {
      top: 100px;
    }
  }

  &__wrapper {
    display: flex;
    height: 50px;
    width: 100%;
    top: 40px;
    justify-content: center;
  }

  &__inner {
    position: relative;
    display: flex;
    height: inherit;
    width: 120px;
    justify-content: space-between;

    @media (min-width: $screen-sm) {
      width: 130px;
    }

    #space {
      img {
        height: 30px;
        width: 30px;
      }

      @media (min-width: $screen-sm) {
        img {
          height: 50px;
          width: 50px;
        }
      }
    }
  }
}

// Home Footer
.footer {
  &__graphic {
    &.television {
      display: none;

      @media (min-width: $screen-sm) {
        position: absolute;
        z-index: -1;
        display: block;
        bottom: 0;
        left: 0;
        height: auto;
        width: 25vw;
        min-width: 200px;
        max-width: 387px;
      }

      @media (min-width: $screen-lg) {
        bottom: -5px;
        left: 0;
      }
  
      img {
        position: relative;
        display: block;
        height: auto;
        max-width: 100%;
      }
    }
  
    &.coffee {
      display: none;

      @media (min-width: $screen-sm) {
        position: absolute;
        display: block;
        top: 30%;
        right: 0;
        z-index: -1;
        height: auto;
        width: 25vw;
        min-width: 200px;
        transform: translate(0, -30%);
      }
  
      img {
        position: relative;
        height: auto;
        max-width: 100%;
        transform: rotate(-62deg);
      }
    }
  }

  &__content {
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: column;
  }

  &__bubble {
    display: flex;
    margin-bottom: 16px;
    justify-content: center;

    img {
      position: relative;
      display: block;
      height: auto;
      width: 32vw;
      min-width: 220px;
      max-width: 359px;
    }
  }

  &__heading {
    margin-bottom: 8px;

    h2 {
      color: $gray-dark;
      text-align: center;
      font-weight: $black-type;
      font-size: clamp(24px, 5vw, 48px);
    }
  }

  &__description {
    margin-bottom: 16px;

    p {
      width: 80%;
      margin: 0 auto;
      font-size: 1rem;
      line-height: 1.5;
      text-align: center;
      color: $gray-dark;

      @media (min-width: $screen-sm) {
        width: 70%;
      }
    }
  }

  &__links {
    .socials {
      display: flex;
      flex-direction: row;
      margin: 0;
      padding: 0;
      list-style: none;
      justify-content: center;
    }

    .social {
      margin-right: 4px;

      &:last-of-type {
        margin-right: 0;
      }

      &__link {
        &:hover, &:focus {
          span {
            .fa-circle {
              color: $white;
            }

            .fa-instagram, 
            .fa-linkedin-in,
            .fa-facebook-f,
            .fa-window-maximize {
              color: $gray-dark;
            }
          }
        }

        span {
          font-size: 1.25em;

          .fa-circle {
            color: $pink;
          }

          .fa-instagram, 
          .fa-linkedin-in,
          .fa-facebook-f,
          .fa-window-maximize {
            color: $white;
          }
        }
      }
    }
  }
}