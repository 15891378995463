/* es-lint disable */

/**
  *  Color Map
  */

$black: #000000;
$white: #ffffff;
$gray-bg: #eaeaea;
$gray-dark: #565656;
$gray-darker: #343434;

$blue: #9fb1f0;
$pink: #f0ae9f;
$green: #96cd92;
$yellow: #ffbb0f;
