/**
  *  Spacing Map
  *  Example usage:
  *
  *  $purple-spacing: (
  *    null  : (20px),
  *    small : 20px,
  *    medium: 40px
  *  );
  *
  *  .test{
  *    @include spacing(padding-bottom, $purple-spacing);
  *  }
  */

$spacing-8: (
  null: (
    8px,
  ),
  medium: 8px,
);

$spacing-16: (
  null: (
    8px,
  ),
  medium: 16px,
);

$spacing-24: (
  null: (
    12px,
  ),
  medium: 24px,
);

$spacing-32: (
  null: (
    16px,
  ),
  medium: 32px,
);

$spacing-40: (
  null: (
    20px,
  ),
  medium: 40px,
);

$spacing-48: (
  null: (
    24px,
  ),
  medium: 48px,
);

$spacing-56: (
  null: (
    28px,
  ),
  medium: 56px,
);

$spacing-64: (
  null: (
    32px,
  ),
  medium: 64px,
);

$spacing-72: (
  null: (
    36px,
  ),
  medium: 72px,
);

$spacing-80: (
  null: (
    40px,
  ),
  medium: 80px,
);

$spacing-88: (
  null: (
    44px,
  ),
  medium: 88px,
);

$spacing-96: (
  null: (
    48px,
  ),
  medium: 96px,
);

$spacing-102: (
  null: (
    51px,
  ),
  medium: 102px,
);

$spacing-108: (
  null: (
    54px,
  ),
  medium: 108px,
);

$spacing-116: (
  null: (
    58px,
  ),
  medium: 116px,
);

$spacing-124: (
  null: (
    62px,
  ),
  medium: 124px,
);