/* Reset `button` and button-style `input` default styles */
input[type='submit'],
input[type='reset'],
input[type='button'],
button {
  background: none;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  -webkit-appearance: button; /* for input */
  -webkit-user-select: none; /* for button */
}
input::-moz-focus-inner,
button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/**
 * Base button styles
 */

.btn {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  padding: 4px 12px;
  color: $white;
  cursor: pointer;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0;
  font-weight: $bold;
  background-color: $green;
  border: 1px solid transparent;

  @media (min-width: $screen-lg) {
    padding: 8px 24px;
  }
}