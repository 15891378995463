.container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;

  @media (min-width: $screen-sm) {
    max-width: 540px;
  }

  @media (min-width: $screen-md) {
    max-width: 720px;
  }

  @media (min-width: $screen-lg) {
    max-width: 960px;
  }

  @media (min-width: $screen-xlg) {
    max-width: 1140px;
  }
}